iframe {
  display: none;
}


@font-face {
  font-family: "avenir-black";
  src: url('../src/assets/fonts/avenir_black.otf');
}


// @font-face {
//   font-family: "avenir-roman";
//   src: url('font/avenir_roman.otf');
// }


@font-face {
  font-family: "avenir-light";
  src: url('../src/assets/fonts/avenir_light.otf');
}



/*  GLOBAL */

/* TEXT */


html {
margin:0px;
padding:0px;
height: 100%;
}


body {
margin:0px;
padding:0px;
height: 100%;
}


a {
text-decoration: none;
}


#container {
margin:auto;
background-color: #F7F8F9;
padding-left: 29vw;
padding-right: 29vw;
}


@media (max-width: 640px) { /* SUR MOBILE */
#container {
margin:auto;
background-color: #F7F8F9;
padding-left: 10vw;
padding-right: 10vw;
}
}


#main {
display: flex;
height: 100vh;
align-items: center;
justify-content: center;
flex-direction: column;
}


@media (max-width: 640px) { /* SUR MOBILE */
#main {
display: flex;
height: 100vh;
align-items: center;
justify-content: center;
flex-direction: column;
margin-top: -75px;
}
}


#content {
}


@media (max-width: 640px) { /* SUR MOBILE */
#content {
}
}


#headline {
font-size: 2em;
font-family: 'avenir-black';
text-align: center;
}


@media (max-width: 640px) { /* SUR MOBILE */
#headline {
font-size: 1.6em;
font-family: 'avenir-black';
text-align: center;
}
}


#background {
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: white;
  box-shadow: 1em 0 0 white, -1em 0 0 white;
}


/*#highlight {
  background: linear-gradient(white, white 20%, #FFBA99);
}


/*#highlight {

  animation-delay: 1s;
  animation-name: highlight;
  animation-iteration-count: 1;
  animation-duration: 10s;
  animation-fill-mode: forwards;
  background-repeat: no-repeat;
}


@keyframes highlight {
from {
  background: yellow;
  background-repeat: no-repeat;
  background-size: 0 100%;
}

to {
  background: yellow;
  background-repeat: no-repeat;
  background-size: 50% 100%;
}
}*/


#store-button {
justify-content: center;
display:flex;
margin-top: 8vh;
}


@media (max-width: 640px) { /* SUR MOBILE */
#store-button {
justify-content: center;
display:flex;
flex-direction: column;
align-items: center;
margin-top: 6vh;
}
}


.store_image {
width:200px;
margin-right:10px;
margin-left:10px;
}


#logo_hoop {
width:140px;
padding: 20px;
}


@media (max-width: 640px) { /* SUR MOBILE */
.store_image {
width:200px;
margin-top:20px;
}
}


#icon-video {
display: flex;
align-items: center;
justify-content: center;
height:200px;
width: 200px;
}


@media (max-width: 640px) { /* SUR MOBILE */
#icon-video {
display: flex;
align-items: center;
justify-content: center;
height:200px;
width: 200px;
}
}


/* FOOTER */


#footer {
background-color: white;
text-align: center;
padding-top: 24px;
padding-bottom: 24px;
padding-left:10px;
padding-right:10px;
border-top:solid 1px whitesmoke;
border-radius: 10px;
}


#footer a {
color:#262626;
line-height:1.6;
font-size:0.75em;
margin-left:10px;
margin-right:10px;
font-family:'avenir-black';
text-decoration:none;
}


#footer a:hover {
color:#9c9c9c;
}


@media (max-width: 640px) { /* SUR MOBILE */
#footer a {
color:#262626;
line-height:2.5;
font-size:0.75em;
margin-left:10px;
margin-right:10px;
font-family:'avenir-black';
text-decoration:none;
}
}









#header {
  background-color: white;
  padding-top:2%;
  padding-bottom:2%;
  padding-left:20%;
  padding-right:20%;
  border-bottom:solid 1px whitesmoke;
  }
  
  
  #header a.active {
  background: linear-gradient(white, white 20%, yellow);
  }
  
  
  @media (max-width: 640px) { /* SUR MOBILE */
  #header {
  background-color: white;
  padding-top:2%;
  padding-bottom:2%;
  padding-left:5%;
  padding-right:5%;
  border-bottom:solid 1px whitesmoke;
  }
  }
  
  
  #header a {
  color:#262626;
  line-height:1.6;
  font-size:0.9em;
  margin-right:30px;
  font-family:'avenir-black';
  text-decoration:none;
  }
  
  
  #header a:hover {
  color:#9c9c9c;
  }
  
  
  @media (max-width: 640px) { /* SUR MOBILE */
  #header a {
  color:#262626;
  line-height:2.5;
  font-size:0.8em;
  margin-right:20px;
  font-family:'avenir-black';
  text-decoration:none;
  }
  }


  .okx-img{
    max-width: 320px;
    margin: 10px auto;
    width: 100%;
  }